// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="loading">
    <div class="form-layout" v-ctrl-enter="handleSubmitForm">
      <div class="form">
        <a-form
          :ref="formRef"
          :model="form"
          layout="vertical"
          @submit="handleSubmitForm">
          <a-form-item name="name" ref="name">
            <template #label>
              <tooltip-label :title="$t('label.name')" :tooltip="updateHostApiParams.name.description"/>
            </template>
            <a-input
              v-model:value="form.name"
              :placeholder="updateHostApiParams.name.name">
            </a-input>
          </a-form-item>
          <a-form-item name="hosttags" ref="hosttags">
            <template #label>
              <tooltip-label :title="$t('label.hosttags')" :tooltip="updateHostApiParams.hosttags.description"/>
            </template>
            <a-input
              v-model:value="form.hosttags"
              :placeholder="updateHostApiParams.hosttags.name">
            </a-input>
          </a-form-item>
          <a-form-item name="istagarule" ref="istagarule">
            <template #label>
              <tooltip-label :title="$t('label.istagarule')" :tooltip="updateHostApiParams.istagarule.description"/>
            </template>
            <a-switch v-model:checked="form.istagarule" />
          </a-form-item>
          <a-form-item name="guestosasrule" ref="guestosasrule">
            <template #label>
              <tooltip-label :title="$t('label.guestosasrule')"/>
            </template>
            <a-switch v-model:checked="form.guestosasrule"/>
          </a-form-item>
          <a-form-item v-if="form.guestosasrule" name="guestosrule" ref="guestosrule">
            <template #label>
              <tooltip-label :title="$t('label.guestosrule')" :tooltip="updateHostApiParams.guestosrule.description"/>
            </template>
            <a-textarea
              v-model:value="form.guestosrule"
              :placeholder="updateHostApiParams.guestosrule.name">
            </a-textarea>
          </a-form-item>
          <a-form-item v-if="!form.guestosasrule" name="oscategoryid" ref="oscategoryid">
            <template #label>
              <tooltip-label :title="$t('label.oscategoryid')" :tooltip="updateHostApiParams.oscategoryid.description"/>
            </template>
            <a-select
              v-model:value="form.oscategoryid"
              :loading="loading"
              :placeholder="updateHostApiParams.oscategoryid.description || $t('label.oscategoryid')"
              showSearch
              optionFilterProp="label"
              :filterOption="(input, option) => {
                return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }" >
              <a-select-option
                v-for="(oscategory, index) in oscategoryids"
                :value="oscategory.id"
                :key="index">
                {{ oscategory.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div :span="24" class="action-button">
            <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
            <a-button @click="handleSubmitForm" ref="submit" type="primary">{{ $t('label.ok') }}</a-button>
          </div>
        </a-form>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import { mixinForm } from '@/utils/mixin'
import TooltipLabel from '@/components/widgets/TooltipLabel'

export default {
  name: 'HostAdd',
  mixins: [mixinForm],
  components: {
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  inject: ['parentFetchData', 'parentToggleLoading'],
  data () {
    return {
      loading: false,
      oscategoryids: []
    }
  },
  beforeCreate () {
    this.updateHostApi = this.$store.getters.apis.updateHost || {}
    this.updateHostApiParams = {}
    this.updateHostApi.params.forEach(param => {
      this.updateHostApiParams[param.name] = param
    })
  },
  created () {
    this.initForm()
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      const guestOsRule = this.resource.details['guest.os.rule']
      this.form = reactive({
        name: this.resource.name,
        hosttags: this.resource.hosttags,
        istagarule: this.resource.istagarule,
        guestosasrule: guestOsRule !== undefined,
        guestosrule: guestOsRule,
        oscategoryid: this.resource.oscategoryid
      })
    },
    fetchData () {
      this.fetchOsCategoryIds()
    },
    fetchOsCategoryIds () {
      this.loading = true
      api('listOsCategories').then(response => {
        this.oscategoryids = response.listoscategoriesresponse.oscategory || []
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.loading = false
      })
    },
    handleSubmitForm () {
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const formRaw = toRaw(this.form)
        const values = this.handleRemoveFields(formRaw)

        const params = {
          id: this.resource.id,
          name: values.name,
          istagarule: values.istagarule,
          hosttags: values.hosttags
        }

        if (values.guestosasrule === true) {
          params.guestosrule = values.guestosrule
        } else {
          params.oscategoryid = values.oscategoryid || this.oscategoryids.filter(os => os.name === 'None')[0].id
        }

        Object.keys(params).forEach((key) => (params[key] == null) && delete params[key])
        this.loading = true
        api('updateHost', {}, 'POST', params).then(() => {
          this.$message.success({
            content: `${this.$t('label.action.edit.host')} - ${values.name}`,
            duration: 2
          })
          this.$emit('refresh-data')
          this.closeAction()
        }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style lang="scss">

.form {
  width: 80vw;

  @media (min-width: 500px) {
    width: 400px;
  }
}
</style>
